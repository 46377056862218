import * as Sentry from '@sentry/nextjs';

import { clientGet } from '@/services/client';
import { apiEndpoints } from '@/config/endpoints';

export const getEstablishmentGroup = async (id: string) => {
  try {
    const responseEstablishmentGroup = await clientGet(
      apiEndpoints.getEstablishmentGroupMe(id)
    );

    return responseEstablishmentGroup;
  } catch (error) {
    Sentry.captureException(`Error get Establishment Group:, ${error}`);
  }
};

export default getEstablishmentGroup;
