import { LoginAs } from '@/types/loginAs';

export interface DefaultValues {
  email: string;
  password: string;
  loginAs: LoginAs;
}

export const defaultValues: DefaultValues = {
  email: '',
  password: '',
  loginAs: 'Partner',
};
