import React, { FC } from 'react';
import Link from 'next/link';

import { ControlledInput } from '@/components/common/Forms/ControlledInput';
import { Template } from '../common/Template';

import useLoginController from './useLoginController';

export const Login: FC = () => {
  const { handleSubmit, onSubmit, register, control, errors, isSubmitting } =
    useLoginController();

  return (
    <Template showToggle={false} hideEstablishmentName>
      <div className="w-full h-4/6 bg-[#F8F8F8] flex items-center justify-center px-[45px]">
        <form
          className="w-auto flex items-center justify-center flex-col gap-5 p-4"
          onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-3xl text-center tracking-[-0.005em] text-[#393939]">
            Fazer Login
          </h3>
          <ControlledInput
            control={control}
            errors={errors}
            placeholder="E-mail"
            field="email"
            type="email"
            hideLabel
            className="sentry-mask"
          />
          <ControlledInput
            control={control}
            errors={errors}
            placeholder="Senha"
            field="password"
            type="password"
            hideLabel
            className="sentry-mask"
          />
          <div className="w-[100%] flex flex-col">
            <label htmlFor="loginAs" className="text-[#393939] mb-2">
              Entrar no sistema como:
            </label>
            <select
              {...register('loginAs')}
              id="loginAs"
              className="w-[100%] h-[50px] text-[#4a4a4a] pl-[24px] border shadow-[6px_4px_40px_rgba(0,0,0,0.05)] rounded-[5px] border-solid border-[#C5C5C5]">
              <option value="Partner">Estabelecimento</option>
              <option value="Franchisee">Franqueadora</option>
            </select>
          </div>
          <button
            disabled={isSubmitting}
            className="w-[100%] h-[64px] bg-[#D8F32E] rounded-[500px]"
            type="submit">
            {isSubmitting ? 'Carregando...' : 'Fazer login'}
          </button>
          <Link
            href="/resetar-senha"
            className="text-sm text-center tracking-[-0.02em] underline text-[rgba(84,84,84,0.6)]">
            Esqueci minha senha
          </Link>
        </form>
      </div>
    </Template>
  );
};

export default Login;
