import useStore from '@/store';

import { Worker } from '@/types/worker';

export const useSetRole = () => {
  const { updateRole } = useStore(state => state);

  const setRole = (role: Worker) => updateRole(role);

  return {
    setRole,
  };
};

export default useSetRole;
