import * as z from 'zod';

export const schema = z.object({
  email: z
    .string()
    .trim()
    .email({ message: 'E-mail inválido' })
    .transform(value => value.toLowerCase()),
  password: z.string().min(1, { message: 'Campo obrigatório' }),
  loginAs: z.enum(['Partner', 'Franchisee']),
});

export type LoginProps = z.infer<typeof schema>;
