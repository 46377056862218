import React, { FC } from 'react';

import { Title } from '@/components/common/Title';
import { Login } from '@/components/Login';

export const LoginPage: FC = () => (
  <>
    <Title title="Login" />
    <Login />
  </>
);

export default LoginPage;
