import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { SubmitHandler, useForm } from 'react-hook-form';

import { toast } from 'react-toastify';
import { useStore } from '@/store';

import { zodResolver } from '@hookform/resolvers/zod';
import useSetRole from '@/hooks/useSetRole';

import { auth } from '@/services/auth';
import { clientGet } from '@/services/client';
import { setItemStorage, clearStorage } from '@/services/storage';
import { getEstablishmentGroup } from '@/services/getEstablishmentGroup';

import { apiEndpoints } from '@/config/endpoints';

import { LoginAs } from '@/types/loginAs';
import { Establishment } from '@/types/establishment';
import { User } from '@/types/user';

import { LoginControllerReturnTypes } from './types';

import { schema, LoginProps } from './schema';
import { defaultValues } from './defaultValues';

export const useLoginController = (): LoginControllerReturnTypes => {
  const router = useRouter();
  const {
    updateEstablishment,
    updateUserName,
    updateUserUuid,
    updateFirstAccess,
    updateWorkerName,
    updateLoginAs,
    updateEstablishmentGroup,
  } = useStore(state => state);
  const { setRole } = useSetRole();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });

  const handleSuccessfulLogin = async (response: User) => {
    try {
      updateWorkerName(response.name);
      updateFirstAccess(response.first_access);
      updateUserUuid(response.establishment_worker_uuid);
      updateLoginAs(response.loginAs);

      const access_token = response.access_token;
      setItemStorage('daypass_access_token', access_token);

      const establishmentGroupID = response?.establishment_group_id;
      const isEstablishmentGroup = !!establishmentGroupID;
      const hasManyEstablishment = response?.establishments_uuid?.length > 1;

      if (response.first_access) {
        router.push('/first-access');
        return;
      }

      if (isEstablishmentGroup) {
        const establishmentGroup = await getEstablishmentGroup(
          establishmentGroupID
        );
        updateUserName(establishmentGroup.name);
        updateEstablishmentGroup(establishmentGroup);
        setItemStorage('daypass_access_token_group', access_token);
        router.push('/financeiro');
        return;
      }

      if (hasManyEstablishment) {
        router.push('/escolha');
        return;
      }

      const establishmentId = response.establishments_uuid[0]?.establishmentId;
      const role = response.establishments_uuid[0]?.role;
      setRole(role);
      await handleSingleEstablishmentLogin(establishmentId);
    } catch (error) {
      Sentry.captureException(`Error during successful login: ${error}`);
    }
  };

  const handleSingleEstablishmentLogin = async (establishmentUuid: string) => {
    try {
      const responseGym = (await clientGet(
        apiEndpoints.getGym(establishmentUuid)
      )) as Establishment;

      if (!responseGym) {
        clearStorage('daypass_access_token');
        toast.error('Academia não encontrada', {
          position: 'top-right',
          autoClose: 2000,
        });
        return;
      }

      updateUserName(responseGym.name);
      updateEstablishment(responseGym);
      router.push('/');
    } catch (error) {
      Sentry.captureException(
        `Error during single establishment login: ${error}`
      );
    }
  };

  const onSubmit: SubmitHandler<any> = async (data: LoginProps) => {
    try {
      const response = await auth(data);

      const loginAs: LoginAs = data.loginAs;

      if (response) {
        await handleSuccessfulLogin({ ...response, loginAs });
      }
    } catch (error) {
      Sentry.captureException(`Error during form submission: ${error}`);
    }
  };

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
    register,
    isSubmitting,
  };
};

export default useLoginController;
